import React from "react";
import { Link } from "gatsby";

import { IoFlashOutline, IoHeartOutline, IoShieldCheckmarkOutline, IoRocketOutline } from "react-icons/io5";

import content_json from "../content/content-data.json";
import content_js from "../content/content-data.js";

export default function MenuArticles ({ location }) {
	
	const placeIcon = function(path) {
		switch (path) {
			case '/architecture-and-design/':
				return <IoHeartOutline size={35} />
			case '/speed/':
				return <IoFlashOutline size={30} />
			case '/security/':
				return <IoShieldCheckmarkOutline size={35} />
			case '/technologies/':
				return <IoRocketOutline size={35} />
			default:
				return <IoRocketOutline size={35} />
		}
	}

	return(
		<div class="article_menu">

			<h3>{content_js.articles_menu.heading}:</h3>

			<div class="icons">
				{content_json.articles.map((article) => (
					((location.pathname !== article.path) &&
						<Link to={article.path}>
							<div class={`icon theme-${article.theme}`} title={`${article.title}`}>
								{placeIcon(article.path)}
							</div>
					</Link>)
				))}
			</div>
		</div>
	);
}

