import * as React from "react";

import Layout from '../components/layout.js';
import MenuArticles from '../components/articles_menu.js';

import content_data from '../content/content-data.js';

const Article = ({ location }) => {

	return (
	 <Layout location={location}>
	 	<div id="wuwei">
			{content_data.articles[location.pathname]?.article_content}

			<MenuArticles location={location} />
	 	</div>
	 </Layout>
	)
}

export default Article